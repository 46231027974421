import React from 'react';
// import FontAwesome from 'react-fontawesome';
import { Button } from 'react-bootstrap';
import QuoteLinks from './QuoteLinks';
// import QuoteButton from './QuoteButton';

function QuoteButtons(props) {
  // console.log('LOADED:', 'components/QuoteButtons.js');
  // console.log('PROPS:', props);

  return (
    <div className='buttons'>
      <QuoteLinks quote={props.quote} />
      {/* <QuoteButton cssclassname='social-button bi bi-facebook' message='Share on Facebook...' />
      <QuoteButton cssclassname='social-button bi bi-twitter' message='Share on Twitter...' />
      <QuoteButton cssclassname='social-button bi bi-google' message='Share on Google...' />
      <QuoteButton cssclassname='social-button bi bi-github' message='Share on GitHub...' />
      <QuoteButton cssclassname='social-button bi bi-instagram' message='Share on Instagram...' />
      <QuoteButton cssclassname='social-button bi bi-linkedin' message='Share on LinkedIn...' />
      <QuoteButton cssclassname='social-button bi bi-pinterest' message='Share on Pinterest...' />
      <QuoteButton cssclassname='social-button bi bi-vk' message='Share on VK...' />
      <QuoteButton cssclassname='social-button bi bi-stack-overflow' message='Share on Stack Overflow...' />
      <QuoteButton cssclassname='social-button bi bi-youtube' message='Share on YouTube...' />
      <QuoteButton cssclassname='social-button bi bi-slack-hash' message='Share on Slack Hash...' />
      <QuoteButton cssclassname='social-button bi bi-dribble' message='Share on Dribble...' />
      <QuoteButton cssclassname='social-button bi bi-reddit' message='Share on Reddit...' />
      <QuoteButton cssclassname='social-button bi bi-whatsapp' message='Share on WhatsApp...' /> */}
      <Button id='new-quote' onClick={props.handleNewQuote} variant='dark'>New Quote</Button>
    </div>
  );
}

export default QuoteButtons;
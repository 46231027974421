import React from 'react';
import Nav from 'react-bootstrap/Nav';

function QuoteLink(props) {
  // console.log('PROPS:', props);
  let href = 'https://twitter.com/intent/tweet?hashtags=quotes&related=freecodecamp&text=';
  href += props.quote.join(' ');

  return (
    <Nav>
        <Nav.Item>
            <Nav.Link id='tweet-quote' href={href} target='_blank' className='social-button'>
                <i className='bi bi-twitter'></i>
            </Nav.Link>
        </Nav.Item>
    </Nav>
  );
}

export default QuoteLink;
var colors = [
    '#16a085',
    '#27ae60',
    '#2c3e50',
    '#f39c12',
    '#e74c3c',
    '#9b59b6',
    '#FB6964',
    '#342224',
    '#472E32',
    '#BDBB99',
    '#77B1A9',
    '#73A857'
];

function handleUI() {
    let color = colors[Math.floor(Math.random() * colors.length)];
    document.body.style.background = color;
    let newQuoteButton = document.getElementById('new-quote');
    newQuoteButton.style.background = color;
    newQuoteButton.style.borderColor = color;
    let socialButtons = document.getElementsByClassName('social-button');
    for (let item of socialButtons) {
        item.style.color = color;
    }
    let quoteText = document.getElementsByClassName('quote-text')[0];
    quoteText.style.color = color;
}

export default handleUI;
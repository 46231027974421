// import '../styles/App.css';
import QuoteBox from '../components/QuoteBox';
import Footer from '../components/Footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div id="wrapper">
          <QuoteBox />
          <Footer />
        </div>
      </header>
    </div>
  );
}

export default App;

import React from 'react';
import useFetch from '../scripts/useFetch';
import QuoteText from './QuoteText';

function QuoteBox(props) {
  // console.log('PROPS:', props);
  // console.log('DATABASE:', props.database);
  // const URL = 'https://gist.githubusercontent.com/camperbot/5a022b72e96c4c9585c32bf6a75f62d9/raw/e3c6895ce42069f0ee7e991229064f167fe8ccdc/quotes.json';
  const URL = 'https://quotes.unfinited.work/quotes.json';
  const [quotesData] = useFetch(URL);

  return (
    <div id='quote-box'>
        <QuoteText quotesData={quotesData} />
    </div>
  );
}

export default QuoteBox;

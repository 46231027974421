import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import QuoteButtons from '../components/QuoteButtons';
import handleUI from '../scripts/handleUI';

function QuoteText(props) {
  // console.log('PROPS:', props);
  let data = props['quotesData'];

  const [arrQuote, setQuote] = useState(0);
  useEffect(() => {
    let quote = ['Anything that can go wrong will go wrong.', 'Johann Sebastian Murphy'];
    if (data) {
      quote = getQuote(data['quotes']);
    }
    setQuote(quote);
  }, [props, data]);

  const handleNewQuote = () => {
    let quote = ['Anything that can go wrong will go wrong.', 'Johann Sebastian Murphy'];
    if (data) {
      quote = getQuote(data['quotes']);
    }
    setQuote(quote);
    handleUI();
  }

  return (
    <div className='quote-text'>
      <FontAwesome name='quote-left' />
      {arrQuote &&
        <>
          <span id='text'>{arrQuote[0]}</span>
          <div className='quote-author'>- <span id='author'>{arrQuote[1]}</span></div>
          <QuoteButtons handleNewQuote={handleNewQuote} quote={arrQuote} />
        </>
      }
    </div>
  );
}

function getRandomQuote(quotesData) {
  return quotesData[
    Math.floor(Math.random() * quotesData.length)
  ];
}

function getQuote(quotesData) {
  let randomQuote = getRandomQuote(quotesData);
  let currentQuote = randomQuote.quote;
  let currentAuthor = randomQuote.author;

  return [currentQuote, currentAuthor];
}

export default QuoteText;